import dayjs from 'dayjs'
import { Dispatch, ReactNode, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ArrowRight } from '../ArrowRight.tsx'
import { showGeneratedSummary, UsedLinks } from '../helpers.ts'
import AudioPlayerWrapper from './AudioPlayerWrapper.tsx'
import MyMarkdown from './Markdown.tsx'
import { ResultFunction } from './ResultFunction.tsx'
import { ResultSearchZueriEntry, SearchResult } from './ResultSearchZueriEntry.tsx'

import('dayjs/locale/de')

export const Result = (props: {
  queryResult: string
  query: string
  erzFunction: { query: string; function: string } | null
  linksResult: string
  changeModel: () => void
  isLoading: boolean
  maybeBadRating: boolean
  quickFeedback: ReactNode
  otherModelButton: boolean
  userId: string | null
  setLinksResult: Dispatch<SetStateAction<string>>
  usedLinksResult: UsedLinks[]
}) => {
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)

  const DO_AUDIO_BUTTON = !!queryParams.get('audio')

  const searchResults: SearchResult[] = useMemo(() => {
    return props.usedLinksResult?.map(link => {
      const breadcrumbsText = link.title?.split('>') || []
      const title = breadcrumbsText.pop() || ''
      const breadcrumbs = breadcrumbsText
        .map(breadcrumb => breadcrumb.trim())
        .map(breadcrumb => ({ text: breadcrumb, link: '' }))

      return {
        title,
        image: link.smallImage,
        description: link.description || '',
        date: dayjs(link.date).format('dddd, D. MMMM YYYY') || '',
        breadcrumbs: breadcrumbs,
        currentSection: link.considered ? t('answers.considered_articles') : t('answers.not_considered_articles'),
        link: link.url,
        summary: link.summary || '',
        type: link.type || null,
      }
    })
  }, [props.usedLinksResult, t])

  if (props.queryResult === '') {
    return null
  }
  dayjs.locale('de') // use loaded locale globally

  const hasFunctionResult = props.erzFunction?.function && props.erzFunction.function.length > 0

  const hasGeneratedSummary = searchResults.some(result => showGeneratedSummary(result))
  return (
    <>
      <div className="resultBoxContainer">
        {props.queryResult !== t('results.initial') && searchResults.length > 0 && (
          <div className="resultBox links" translate="no">
            <>
              <h1>{t('answers.searchresults')}</h1>
            </>

            {searchResults.length > 0 && (
              <div className="zueriSearchResult" translate="no">
                <ResultSearchZueriEntry entries={searchResults} urlPrefix={''} />
                {hasGeneratedSummary && (
                  <>
                    <hr />
                    <p className={'description'}>* AI generated summary</p>
                  </>
                )}
              </div>
            )}
          </div>
        )}
        <div className="resultBox chatbot" translate="no">
          {props.queryResult !== t('results.initial') && searchResults.length > 0 && (
            <>
              <h1>{t('answers.answer')}</h1>
              <hr />
            </>
          )}
          {hasFunctionResult && <ResultFunction functionQuery={props.erzFunction} userId={props.userId} />}
          {hasFunctionResult && (
            <p>
              <em>Originale Antwort:</em>
            </p>
          )}
          <MyMarkdown
            markdown={
              props.queryResult +
              (props.isLoading
                ? ' ![](https://chregus.rokka.io/dynamic/crop-width-200-height-25--resize-width-20/o-af-1/b9a927/circles-menu-1.gif)'
                : '')
            }
            usedLinks={props.usedLinksResult}
          ></MyMarkdown>
          {DO_AUDIO_BUTTON && !props.isLoading && props.queryResult && <AudioPlayerWrapper text={props.queryResult} />}
          {props.otherModelButton && props.maybeBadRating && (
            <>
              {' '}
              <hr />
              <div className="maybeBadRating">
                <span style={{ marginRight: '0.5em' }}>{t('answers.unhappy')}</span>
                <button type="submit" onClick={props.changeModel} className="button button--model">
                  <span>{t('answers.otherModelButton')}</span>
                  <ArrowRight />
                </button>
              </div>
            </>
          )}

          {props.linksResult && (
            <>
              <hr />
              {props.quickFeedback}
            </>
          )}
          {props.otherModelButton && !props.maybeBadRating && (
            <>
              <hr />

              <button type="submit" onClick={props.changeModel} className="button button--model">
                <span>{t('answers.otherModelButton')}</span>
                <ArrowRight />
              </button>
            </>
          )}
        </div>
      </div>
    </>
  )
}
